import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import usePageTracking from './hooks/usePageTracking';

import HomePage from './pages/Home';

const Routers = () => {
	// usePageTracking();

	return (
		<Switch>
			<Route path="/" exact={true} component={HomePage} />
		</Switch>
	);
};

export default Routers;
