import React, {useLayoutEffect, useRef} from 'react'
import {BsX} from "react-icons/bs"
import styled from 'styled-components'

const Wrapper = styled.div`
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .modal {
        &--box {
            position: relative;
            width: 100%;
            max-width: 480px;
            height: auto;
            padding: 20px;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(000,000,000,.3);
        }

        &--close {
            position: absolute;
            z-index: 99;
            top: 5px; right: 5px;
            width: 30px;
            height: 30px;
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            font-size: 26px;
            font-weight: 800;
            color: #333;
        }
    }
`

const Modal = (props) => {
    const boxModal = useRef()

    useLayoutEffect(()=>{
        document.body.style.overflow = 'hidden'

        return ()=>{
            document.body.style.overflow = ''
        }
    }, [])

    const handleWindowClick = (e) => {
        if(!boxModal.current.contains(e.target)) {
            props.closeAction()
        }
    }

    const handleClose = (e) => {
        props.closeAction()
    }

    return (
        <Wrapper onClick={handleWindowClick}>
            <div className={`modal--box ${props.size}`}  ref={boxModal}>
                <div className="modal--close" onClick={handleClose}>
                    <BsX />
                </div>

                {props.children}
            </div>
        </Wrapper>
    )
}

export default Modal