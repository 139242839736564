import React, {useState, useEffect} from 'react'
import {handleShopsToCities} from '../helpers/Shops'
import useQuery from '../hooks/useQuery'

const SelectShops = (props) => {
    const [shops, setShops] = useState([])

    const query = useQuery()
    const queryUf = query.get('uf')
    const queryShopId = query.get('id')

    const getShopList = props.shops

    useEffect(()=>{
        if(getShopList) {
            const arrWorks = ['Max']
            const arr = []
            const arr_p = []
            const arr_s = []

            getShopList.forEach(e => arrWorks.forEach(i => (!e.nome.indexOf(i)) ? arr.push(e) : null))

            arr.forEach(e => (e.uf === 'Paraná') ? arr_p.push(e) : arr_s.push(e))

            if(arr_p.length !== 0 && arr_s.length !== 0) {
                switch (queryUf) {
                    case 'pr':
                        setShops(handleShopsToCities(arr_p))
                    break;

                    case 'sp':
                        setShops(handleShopsToCities(arr_s))
                    break;
                
                    default:
                        setShops([])
                    break;
                }
            }
        }
    }, [getShopList])
    
    const handleShopSelected = (e) => props.handleShopSelected(e)

    return (
        <form>
            <div className="form-group mb-0">
                {shops &&
                    <select onChange={(handleShopSelected)} value={(queryShopId) ? queryShopId : 0} name="shop" className="form-control form-control--arrow w-100">
                        <option value="0" disabled={true}>-- Selecione uma loja --</option>
                        {shops.map((shop, shopIndex) => 
                            <optgroup key={shopIndex} label={shop.cidade}>
                                {shop.lojas.map((loja, lojaIndex) => 
                                    <option key={lojaIndex} value={loja.id}>{loja.nome}</option>
                                )}    
                            </optgroup>
                        )}
                    </select>
                }
            </div>
        </form>
    )
}

export default SelectShops