import React, {useState} from 'react'
import styled from 'styled-components'
import { AiOutlineShareAlt, AiOutlineWhatsApp } from "react-icons/ai";
import Modal from '../components/Modal'
import ModalRecebaOfertas from '../components/ModalRecebaOfertas'


const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #34af23;
    border-top: 1px solid rgba(255,255,255,.8);
    box-shadow: 0 0 8px rgba(0,0,0,.2);

    .share {
        &__link {
            display: flex;
            justify-content: center;
            padding: 8px 0;
            border-radius: 2rem;
            background-color: rgba(255,255,255,.3);
            text-align: center;
            font-size: 18px;
            color: #fff;

            &:not([href]) {
                cursor: pointer;
                color: #fff;

                &:hover, &:focus {
                    background-color: rgba(255,255,255,.1);
                    text-decoration: none;
                    color: #fff;
                }
            }

            span {
                margin-left: 6px;
                font-size: 12px;
            }

            &:hover, &:focus {
                background-color: rgba(255,255,255,.1);
                text-decoration: none;
            }
        }
    }


`

const Foobar = (props) => {
    const [modal, setModal] = useState(false)

    return (
        <Wrapper>
            {modal && 
                <Modal closeAction={()=>setModal(!true)}>
                   <ModalRecebaOfertas whatsapp={props.whatsapp} />
                </Modal>
            }

            <div className="container-fluid mx-0 px-0">
                <div className="row no-gutters">
                    <div className="col-6">
                        <a href={`https://wa.me/?text=${document.location.href}`} target="_blank" className="share__link ml-1">
                            <AiOutlineShareAlt/> <span>Compartilhar</span>
                        </a>
                    </div>
                    <div className="col-6">
                        <a onClick={()=>{setModal(true)}} className="share__link ml-1">
                            <AiOutlineWhatsApp/> <span>Receber Ofertas</span>
                        </a>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Foobar