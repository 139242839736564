import {useState, useEffect} from 'react'

const useFetch = (URL, OPTIONS = {}) => {
    const [data, updateData] = useState()

    const BASE_API = 'https://institucional.supermuffato.com.br/webtools/services/api'

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(BASE_API + URL, OPTIONS)
            const json = await response.json()
            updateData(json)
        }
        fetchData()
    }, [URL])

    return data
}

export default useFetch