export const handleShopsToCities = (data) => {
    const arr = []
    
    for (var i = 0; i < data.length; i++) {
        let cidadeIgual = false
        for (var j = 0; j < i; j++) {
            if (arr[j] && data[i].cidade === arr[j].cidade) {
                arr[j].lojas.push(data[i])
                cidadeIgual = true
                break
            }
        }
        
        if (!cidadeIgual) {
            arr.push({
                cidade: data[i].cidade,
                lojas: [data[i]]
            })
        }
    }

    return arr
}