import React from 'react';
// import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';

import { createGlobalStyle } from 'styled-components';
import Routes from './Routes';
import { Template } from './components/MainComponents';
import Layout from './components/layout/Layout';

// ReactGA.initialize('G-N4440DTS25');

const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #3b3b3b;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
        outline: 0;
    }
`;
const App = () => {
	return (
		<>
			<GlobalStyle />
			<Layout>
				<Template>
					<BrowserRouter basename="/">
						<Routes />
					</BrowserRouter>
				</Template>
			</Layout>
		</>
	);
};

export default App;
