import React from 'react'
import styled from 'styled-components'
import {maskPhone} from '../helpers/Mask'
import {AiOutlineWhatsApp} from "react-icons/ai";

const Wrapper = styled.div`
    text-align: center;

    h2 {
        margin: 20px 0 15px;
        font-size: 22px;
        font-weight: 700;
    }

    p {
        font-size: 16px;

    }

    .phone {
        font-size: 24px;
        font-weight: 600;
        color: #34af23;
    }

    .featured {
        display: inline-block;
        margin-bottom: 15px;
        padding: 14px 20px;
        border-radius: .6rem;
        background-color: #34af23;
        font-size: 26px;
        font-weight: 700;
        color: #fff;

        small {
            display: block;
            font-size: 15px;
        }

        &:hover, &:focus {
            text-decoration: none;
        }
    }
`

const ModalRecebaOfertas = (props) => {
    
    const handleNumber = () => {
        let res,
            toArr,
            whats = props.whatsapp

        whats = whats.replace(/\D/g, '')
        toArr = whats.split("")
                toArr.splice(2, 0, '9')
        res = toArr.join('')

        return res
    }

    const handleLinkWhats = () => {
        let phone = handleNumber()
        let API = `https://api.whatsapp.com/send?phone=55${phone}&text=Quero%20preco%20baixo`

        return API
    }

    return (
        <Wrapper>
            <h2>Cadastre-se e receba ofertas em seu WhatsApp:</h2>
            <p>Adicione o número abaixo (correspondente à sua região) nos seus contatos do celular:</p>
            <p className="phone"><AiOutlineWhatsApp/> {maskPhone(handleNumber())}</p>
            <p>Envie um whats dizendo "Quero preço baixo" para o número que você adicionou:</p>
            <a href={handleLinkWhats()} className="featured">Quero preço baixo!</a>
            <p style={{fontSize: '13px'}}>Pronto! Agora você vai sempre receber os encartes do Max Atacadista!</p>
        </Wrapper>
    )
}

export default ModalRecebaOfertas