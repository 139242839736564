import React from 'react'
import styled from 'styled-components'
import {useWindowSizeWidth} from '../../hooks/useResize'
import colors from '../../helpers/Colors'
import logoMaxAtacadista from '../../images/logo-max-atacadista.svg'

const Wrapper = styled.div `
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 4px solid ${colors.blue};
    border-bottom: 1px solid ${colors.white};
    background-color: #f2f2f2;
    box-shadow: 0 0 20px rgba(0,0,0,.2);

    .logo {
        max-width: 140px;
        height: auto;
    }

    .call {
        margin: 0;
        font-size: 30px;
        font-weight: 700;
        color: ${colors.blue};

        span {
            display: block;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 500;
            color: #333;
        }
    }
`

const Header = () => {
    const vWidth = useWindowSizeWidth()

    return (
        <Wrapper>
            <div className={(vWidth >= 768) ? 'container' : 'container text-center'}>
                <div className="row d-flex align-items-md-center">
                    <div className="col-md-6">
                        <img src={logoMaxAtacadista} className="logo" alt=""/>
                    </div>

                    <div className="col-md-6 mt-md-0 mt-4">
                        <h2 className="call text-md-right"><span>Tele vendas</span>0800 727 9767</h2>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Header